import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { API_URL } from "../../config/apiConfig";
import Copyright from "../internals/components/Copyright";

export default function EvacuationSimulationGrid() {
  const [apiKey, setApiKey] = useState("");
  const [userLocationLat, setUserLocationLat] = useState("");
  const [userLocationLon, setUserLocationLon] = useState("");
  const [result, setResult] = useState(null);
  const [loadingEvacuationRoute, setLoadingEvacuationRoute] = useState(false);
  const token = localStorage.getItem("token");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [isSimulating, setIsSimulating] = useState(false);

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/api-keys/my-api-keys`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.responseCode === 200) {
          setApiKey(data.data.api_key);
        } else {
          setApiKey("No API key found. Please generate a new one");
        }
      } catch (err) {
        setApiKey("No API key found. Please generate a new one");
      }
    };

    fetchApiKey();
  }, [token]);

  useEffect(() => {
    const fetchAllLocations = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/admin/locations/all`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) throw new Error("Failed to fetch locations");

        const result = await response.json();
        if (result.responseCode !== 200) {
          throw new Error(result.message || "Failed to fetch locations");
        }

        const allLocations = result.data;

        setProvinces(
          allLocations.filter((item) => item.code.split(".").length === 1)
        );
        setCities(
          allLocations.filter((item) => item.code.split(".").length === 2)
        );
        setDistricts(
          allLocations.filter((item) => item.code.split(".").length === 3)
        );
        setVillages(
          allLocations.filter((item) => item.code.split(".").length === 4)
        );
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchAllLocations();
  }, [token]);

  const handleSimulateEvacuationRoute = async () => {
    if (!apiKey || apiKey === "No API key found. Please generate a new one") {
      Swal.fire("Error", "API Key is required.", "error");
      return;
    }

    if (!userLocationLat || !userLocationLon) {
      Swal.fire("Error", "Latitude and Longitude are required.", "error");
      return;
    }

    try {
      setIsSimulating(true);
      setLoadingEvacuationRoute(true);
      const response = await fetch(`${API_URL}/v1/evacuation-points/select`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${apiKey}`,
        },
        body: JSON.stringify({
          latitude: parseFloat(userLocationLat),
          longitude: parseFloat(userLocationLon),
        }),
      });

      const responseData = await response.json();
      console.log("Response Data:", responseData);

      if (response.ok && responseData.responseCode === 200) {
        const { location } = responseData.data.selectedPoint;
        const [provinceCode, cityCode, districtCode, villageCode] =
          location.code.split(".");

        const province = provinces.find((item) => item.code === provinceCode);
        const city = cities.find(
          (item) => item.code === `${provinceCode}.${cityCode}`
        );
        const district = districts.find(
          (item) => item.code === `${provinceCode}.${cityCode}.${districtCode}`
        );
        const village = villages.find(
          (item) =>
            item.code ===
            `${provinceCode}.${cityCode}.${districtCode}.${villageCode}`
        );

        setResult({
          status: "success",
          data: {
            ...responseData,
            hierarchy: {
              province: province?.name || "Unknown Province",
              city: city?.name || "Unknown City",
              district: district?.name || "Unknown District",
              village: village?.name || "Unknown Village",
            },
          },
        });
      } else {
        // Handle error case
        setResult({
          status: "error",
          data: {
            message: responseData.message || "An unknown error occurred.",
          },
        });
      }
    } catch (error) {
      setResult({
        status: "error",
        data: { message: error.message || "An unknown error occurred." },
      });
    } finally {
      setLoadingEvacuationRoute(false);
      setIsSimulating(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 4, mt: 4 }}>
        {/* All Forms */}
        <Box sx={{ flex: 1, border: "1px solid #ccc", borderRadius: 2, p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Evacuation Route Simulation
          </Typography>

          <TextField
            fullWidth
            label="API KEY"
            value={apiKey || "No API key found. Please generate a new one"}
            autoComplete="off"
            type="text"
            disabled
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            label="Latitude"
            value={userLocationLat}
            autoComplete="off"
            type="number"
            onChange={(e) => setUserLocationLat(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Longitude"
            value={userLocationLon}
            autoComplete="off"
            type="number"
            onChange={(e) => setUserLocationLon(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSimulateEvacuationRoute}
            disabled={loadingEvacuationRoute || isSimulating}
          >
            {loadingEvacuationRoute ? (
              <CircularProgress size={24} />
            ) : (
              "Simulate Evacuation Route"
            )}
          </Button>
        </Box>

        {/* Result Box */}
        <Box
          sx={{
            flex: 1,
            border: "1px solid #ccc",
            borderRadius: 2,
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Simulation Results
          </Typography>
          {result ? (
            result.status === "error" ? ( // Handle error case
              <Typography variant="body1" color="error">
                {result.data.message}
              </Typography>
            ) : (
              <Box>
                <Typography variant="body1" gutterBottom>
                  Status: <strong>{result.status}</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Distance:{" "}
                  <strong>
                    {result.data.data.selectedPoint?.distance.toFixed(2) ||
                      "N/A"}{" "}
                    km
                  </strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Latitude:{" "}
                  <strong>
                    {result.data.data.selectedPoint?.latitude || "N/A"}
                  </strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Longitude:{" "}
                  <strong>
                    {result.data.data.selectedPoint?.longitude || "N/A"}
                  </strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Location Code:{" "}
                  <strong>
                    {result.data.data.selectedPoint?.location?.code || "N/A"}
                  </strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Province:{" "}
                  <strong>{result.data.hierarchy?.province || "N/A"}</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  City: <strong>{result.data.hierarchy?.city || "N/A"}</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  District:{" "}
                  <strong>{result.data.hierarchy?.district || "N/A"}</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Village:{" "}
                  <strong>{result.data.hierarchy?.village || "N/A"}</strong>
                </Typography>
              </Box>
            )
          ) : (
            <Typography variant="body1">No results available.</Typography>
          )}
        </Box>
      </Box>
      <Copyright sx={{ mt: 4 }} />
    </Box>
  );
}
