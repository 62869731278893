import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL, SHAKEMAP_API_URL } from "../../config/apiConfig";
export default function DetailEarthquakeGrid() {
  const { id } = useParams();
  const [earthquake, setEarthquake] = useState(null);
  const [mmiData, setMmiData] = useState({});
  const [apiKey, setApiKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/api-keys/my-api-keys`, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        if (data.responseCode === 200) {
          setApiKey(data.data.api_key);
        } else {
          throw new Error("API Key not found");
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchEarthquakeDetails = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/earthquakes/${id}`);
        if (!response.ok) throw new Error("Failed to fetch earthquake details");

        const earthquakeData = await response.json();
        setEarthquake(earthquakeData.data);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchApiKey()
      .then(fetchEarthquakeDetails)
      .finally(() => setLoading(false)); // **Pastikan loading dihentikan**
  }, [id]);

  useEffect(() => {
    const fetchMmiData = async () => {
      if (!apiKey || !earthquake) return;

      try {
        const mmiResponse = await fetch(
          `${API_URL}/v1/earthquakes/mmi/${earthquake.eventId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apiKey,
            },
            body: JSON.stringify({
              lat: earthquake.latitude,
              lon: earthquake.longitude,
            }),
          }
        );

        if (!mmiResponse.ok) throw new Error("Failed to fetch MMI data");

        const mmiDataResponse = await mmiResponse.json();
        if (mmiDataResponse.responseCode !== 200)
          throw new Error(mmiDataResponse.message);

        const groupedData = {};
        mmiDataResponse.data.forEach((item) => {
          const mmiLevel = item.mmi_level;
          if (!groupedData[mmiLevel]) groupedData[mmiLevel] = [];
          groupedData[mmiLevel].push({
            latitude: item.latitude,
            longitude: item.longitude,
          });
        });

        const sortedGroupedData = Object.keys(groupedData)
          .sort((a, b) => b - a)
          .reduce((acc, key) => {
            acc[key] = groupedData[key];
            return acc;
          }, {});

        setMmiData(sortedGroupedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (apiKey && earthquake) {
      fetchMmiData();
    }
  }, [apiKey, earthquake]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        p: 4,
      }}
    >
      {/* Title */}
      <Typography component="h1" variant="h4" sx={{ mb: 4 }}>
        Earthquake Event Details
      </Typography>

      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row", // Set to row for side-by-side layout
          gap: 4,
        }}
      >
        {/* Earthquake Information Table */}
        <Box sx={{ flex: 1 }}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Earthquake Information
          </Typography>

          {earthquake && (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>ID</strong>
                    </TableCell>
                    <TableCell>{earthquake.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Event ID</strong>
                    </TableCell>
                    <TableCell>{earthquake.eventId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Date</strong>
                    </TableCell>
                    <TableCell>
                      {new Date(earthquake.date).toLocaleDateString("id-ID")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Time</strong>
                    </TableCell>
                    <TableCell>{earthquake.time}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Latitude</strong>
                    </TableCell>
                    <TableCell>{earthquake.latitude}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Longitude</strong>
                    </TableCell>
                    <TableCell>{earthquake.longitude}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Magnitude</strong>
                    </TableCell>
                    <TableCell>{earthquake.magnitude}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Depth</strong>
                    </TableCell>
                    <TableCell>{earthquake.depth} km</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Location</strong>
                    </TableCell>
                    <TableCell>{earthquake.location}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Created At</strong>
                    </TableCell>
                    <TableCell>
                      {new Date(earthquake.created_at).toLocaleString("id-ID")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>

        {/* MMI Map */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {earthquake && (
            <img
              src={`${SHAKEMAP_API_URL}/v1/shakemap/intensity/${earthquake.eventId}`}
              alt="MMI Map"
              style={{ maxWidth: "100%", height: "auto", borderRadius: 8 }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = "none";
                const parent = e.target.parentNode;
                const message = document.createElement("p");
                message.textContent = "MMI Map could not be loaded";
                message.style.color = "red";
                message.style.fontWeight = "bold";
                parent.appendChild(message);
              }}
            />
          )}
        </Box>
      </Box>

      {/* MMI Data Section */}
      <Box>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          MMI Data
        </Typography>
        {Object.entries(mmiData).map(([mmiLevel, locations], index) => (
          <Box key={index} sx={{ mt: 4 }}>
            <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
              MMI {mmiLevel}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {locations.map((loc, locIndex) => (
                    <TableRow key={locIndex}>
                      <TableCell>
                        <strong>Latitude</strong>
                      </TableCell>
                      <TableCell>{loc.latitude}</TableCell>
                      <TableCell>
                        <strong>Longitude</strong>
                      </TableCell>
                      <TableCell>{loc.longitude}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
