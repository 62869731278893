import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import { API_URL } from "../../config/apiConfig";

export default function AiModelGrid() {
  const [models, setModels] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");

  const fetchModels = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/v1/ai/getAiModel`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error("Failed to fetch AI models");
      const data = await response.json();
      setModels(data.data || { primaryModel: null, backupModel: null });
    } catch (error) {
      console.error("Error fetching AI models:", error);
    }
    setLoading(false);
  }, [token]);

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  const handleUpload = async () => {
    if (!selectedFile) return;
    setUploading(true);
    try {
      const formData = new FormData();
      formData.append("model", selectedFile);
      const response = await fetch(`${API_URL}/v1/ai/uploadAiModel`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });
      if (!response.ok) throw new Error("Failed to upload model");
      setOpen(false);
      fetchModels();
      Swal.fire("Success", "Model uploaded successfully!", "success");
    } catch (error) {
      Swal.fire("Error", "Failed to upload AI model", "error");
    }
    setUploading(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${API_URL}/v1/ai/delete/${id}`, {
            method: "DELETE",
            headers: { Authorization: `Bearer ${token}` },
          });
          if (!response.ok) throw new Error("Failed to delete AI model");
          fetchModels();
          Swal.fire("Deleted!", "Your model has been deleted.", "success");
        } catch (error) {
          Swal.fire("Error", "Failed to delete AI model", "error");
        }
      }
    });
  };

  const handleDownloadDataset = async () => {
    try {
      const response = await fetch(`${API_URL}/v1/dataset/export-dataset`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error("Failed to fetch dataset path");
      const data = await response.json();
      const filePath = data.data.filePath;
      const fileName = filePath.split("/").pop();

      const downloadLink = document.createElement("a");
      downloadLink.href = `${API_URL}/v1${filePath}`;
      downloadLink.setAttribute("download", fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      Swal.fire("Error", "Failed to download dataset", "error");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ width: "100%", maxWidth: "1700px" }}>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Upload AI Model
      </Button>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[models?.primaryModel, models?.backupModel]
              .filter(Boolean)
              .map((model) => (
                <TableRow key={model.id}>
                  <TableCell>{model.name}</TableCell>
                  <TableCell>{model.model}</TableCell>
                  <TableCell>
                    {!model.isPrimary && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDelete(model.id)}
                      >
                        Delete
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadDataset}
        >
          Download Dataset
        </Button>
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            p: 4,
            bgcolor: "background.paper",
            borderRadius: 2,
            width: 400,
            mx: "auto",
            mt: 10,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Upload AI Model
          </Typography>
          <input
            type="file"
            accept=".zip"
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={uploading || !selectedFile}
            onClick={handleUpload}
            sx={{ mt: 2 }}
          >
            {uploading ? "Uploading..." : "Upload"}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
